<template>
  <div>
    <div>
      <b-card bg-variant="light" text-variant="black" header="" class="text-center">
        
        <div class="w-100 text-center">
            <br><br><br>
            <h2>
                {{ $t('503 - Služba je dočasně nedostupná.') }}
            </h2>
            <br><br>
            <p>
                {{ $t('Zkuste to prosím později.') }}
            </p>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{ name: 'default-view', params: { }}"
            >
                {{ $t('Zpět') }}
            </b-button>
            <br><br><br>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { /*state, mutations*/ } from '@/store/index.js'

export default {
  components: { },

  computed: { },

  data( ) {
    return {
    }
  },

  mounted( ) { },

  methods: { }
}
</script>

<style scoped>
</style>